import '../assets/css/nds.css';

import React, { Component } from "react"
import Formsy from 'formsy-react';
import { connect } from "react-redux"
import { navigate } from "gatsby"

import Layout from "../components/layout"
import Steps from "../components/scppstep"
import bottomLine from "../images/line.png"
import SEO from "../components/seo"
import * as actionCreators from './../store/actions';
import MyInput from "../components/MyInput"
import MyInputMask from "../components/MyInputMask";
import ButtonLoader from "../components/buttonLoader";
import MyAuto from "../components/MyAuto";

class scpp2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      canSubmit: false,
      nds2Data:{user1_name:'',user1_email:'',user1_title:'',
      user2_name:'',user2_email:'',user2_title:'',
      user3_name:'',user3_email:'',user3_title:''
      }
    };
  }

  componentDidMount = async () => {
    const { showLoader, hideLoader } = this.props;
    
    if(!sessionStorage.ndsver || sessionStorage.ndsver!==process.env.ndsver){
      sessionStorage.clear();
      sessionStorage.ndsver=process.env.ndsver;
    } 
    if(!sessionStorage.nds2Data){
        sessionStorage.nds2Data = window.btoa(JSON.stringify(this.state.nds2Data))
    } 
    this.state.nds2Data = JSON.parse(window.atob(sessionStorage.nds2Data))
    this.setState({nds2Data:JSON.parse(window.atob(sessionStorage.nds2Data))});
    
    //console.log(this.state.nds2Data)
    
     
  }

  

  disableButton = () => {
    this.setState({ canSubmit: false });
  }
  enableButton = () => {
    this.setState({ canSubmit: true,infoError:"" });
  }
  submit = (model) => {
    const { showLoader, hideLoader} = this.props;
    showLoader();
    sessionStorage.nds2Data = window.btoa(JSON.stringify(model));
    hideLoader();
    navigate("/scpp3")
  }
 
   
  render() {
    const { nds2Data, canSubmit } = this.state;
    const { buttonLoader} = this.props;
   
    return (
      <Layout layoutType="nds" >
        <section className="ndsec" ref={(input) => { this.nameInput = input; }}>
        <Steps></Steps>
          <div className="custom-container vehinfo">
           

            <div className="body-content">
              <Formsy ref="nds2Form" onValidSubmit={this.submit} onValid={this.enableButton} onInvalid={this.disableButton}>

              <h3>SIGNER</h3>
              <div className="form-container-inline">
                    <div className="col3">
                        <div className="form-label-group error">
                            <MyInput name="user1_name" id="user1_name" placeholder="Name"  value={nds2Data.user1_name} validationError="Please enter a valid name."
                                isLabelRequired={true} className="form-control" validations={{minLength: 3,maxLength:25}} required />
                        </div>

                    </div>

                    <div className="col3">
                        <div className="form-label-group error">
                        <MyInput name="user1_email" id="user1_email" placeholder="Email"  value={nds2Data.user1_email} validations="isEmail"
                                isLabelRequired={true} className="form-control" validationError="Please enter valid email" required />
                        </div>
                    </div>
                    
                    <div className="col3">
                        <div className="form-label-group error">
                        <MyInputMask name="user1_title" id="user1_title" placeholder="Title" value={nds2Data.user1_title} isLabelRequired={true}
                            className="form-control" validations={{minLength: 2,maxLength:25}} validationError="Please enter a valid Phone." required />
                        </div>
                    </div>
                </div>
              
             

                <h3>SYSTEM USERS</h3>
                  <div className="col3">
                        <div className="form-label-group error">
                            <MyInput name="user2_name" id="user2_name" placeholder="Name"  value={nds2Data.user2_name} validationError="Please enter a valid name."
                                isLabelRequired={true} className="form-control" validations={{minLength: 3,maxLength:25}} required />
                        </div>

                    </div>

                    <div className="col3">
                        <div className="form-label-group error">
                        <MyInput name="user2_email" id="user2_email" placeholder="Email"  value={nds2Data.user2_email} validations="isEmail"
                                isLabelRequired={true} className="form-control" validationError="Please enter valid email" required />
                        </div>
                    </div>
                    
                    <div className="col3">
                        <div className="form-label-group error">
                        <MyInputMask name="user2_title" id="user2_title" placeholder="Title" value={nds2Data.user2_title} isLabelRequired={true}
                            className="form-control" validations={{minLength: 2,maxLength:25}} validationError="Please enter a valid Phone." required />
                        </div>
                    </div>

                    <div className="col3">
                        <div className="form-label-group error">
                            <MyInput name="user3_name" id="user3_name" placeholder="Name"  value={nds2Data.user3_name} validationError="Please enter a valid name."
                                isLabelRequired={true} className="form-control" validations={{minLength: 3,maxLength:25}} />
                        </div>

                    </div>

                    <div className="col3">
                        <div className="form-label-group error">
                        <MyInput name="user3_email" id="user3_email" placeholder="Email"  value={nds2Data.user3_email} validations="isEmail"
                                isLabelRequired={true} className="form-control" validationError="Please enter valid email"  />
                        </div>
                    </div>
                    
                    <div className="col3">
                        <div className="form-label-group error">
                        <MyInputMask name="user3_title" id="user3_title" placeholder="Title" value={nds2Data.user3_title} isLabelRequired={true}
                            className="form-control" validations={{minLength: 2,maxLength:25}} validationError="Please enter a valid Phone." />
                        </div>
                    </div>



                <div className="btn-container">
                    <button type="submit" disabled={!canSubmit || buttonLoader} className={!canSubmit || buttonLoader ? 'btn disabled wizard-btn creat-btn' : 'btn wizard-btn creat-btn'}>
                        <ButtonLoader isLoading={buttonLoader} label={'Continue'} />
                    </button>
                </div>

              </Formsy>
              <div className="txtright">* Field is required</div>
            </div>
          </div>
        </section>
      </Layout>

    );
  }
}

const mapStateToProps = state => {
  return {
    pageLoader: state.primary.pageLoader,
    buttonLoader: state.primary.buttonLoader
  }
};

const mapDispatchToProps = dispatch => {
  return {
    showLoader: () => dispatch(actionCreators.showLoader()),
    hideLoader: () => dispatch(actionCreators.hideLoader()),
    
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(scpp2);